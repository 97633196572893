<template>
	<div class="support">
		<div class="banner">
			<div class="title">
				{{ $t('support.title') }}
			</div>
			<div class="font">
				{{ $t('support.font1') }}
			</div>
		</div>
		<!-- <div class="line"></div> -->
		<div class="body">
			<input type="text" :placeholder="$t('support.input1')" v-model="state.form.title" />
			<textarea :placeholder="$t('support.input2')" maxlength="500" v-model="state.form.question" />
			<input type="text" :placeholder="$t('support.input3')" v-model="state.form.email" />
			<div @click="go" class="button">
				{{ $t('support.button1') }}
			</div>
		</div>
	</div>

	<div class="flagBox" v-if="state.goFlag" @touchmove.stop.prevent>
		<div class="subBox">
			<img src="../assets/support/ok.png" alt="" class="ok" />
			<div class="font">
				{{ $t('support.input4') }}
			</div>
			<div class="button" @click="router.push('/')">
				{{ $t('support.button2') }}
			</div>
		</div>
	</div>
</template>

<script setup>
import { reactive } from 'vue';
import { submitSupport } from '@/api/index';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const router = useRouter();

const state = reactive({
	form: {
		title: '',
		question: '',
		email: '',
	},
	goFlag: false,
});
const go = () => {
	if (state.form.title != '' && state.form.question != '' && state.form.email != '') {
		submitSupport(state.form) // axios请求在这里
			.then((res) => {
				console.log(res);
				if (res.code == 200) {
					// chrome
					document.body.scrollTop = 0;
					// firefox
					document.documentElement.scrollTop = 0;
					// safari
					window.pageYOffset = 0;
					state.goFlag = true;
				}
			})
			.catch((err) => {
				console.log(err);
			});
	} else {
		return;
	}
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 768px) {
	.support {
		width: 100%;
		background: rgba(30, 30, 30, 1);
		color: #ffffff;
		.banner {
			width: 100%;
			height: 393px;
			background: url('../assets/h5support/banner.png');
			background-size: 100% 393px;
			display: flex;
			flex-direction: column;
			align-items: center;
			.title {
				margin: 110px auto 17px;
				font-weight: 500;
				font-size: 36px;
			}
			.font {
				text-align: center;
				width: 250px;
				font-weight: 400;
				font-size: 16px;
			}
		}
		.body {
			width: 100%;
			height: 597px;
			margin: -99px 0 0;
			padding: 24px 15px 40px;
			background: rgba(30, 30, 30, 1);
			input:nth-child(1) {
				margin-bottom: 28px;
				height: 54px;
			}
			// input:nth-child(2) {
			// 	height: 237px;
			// }
			input:nth-child(3) {
				margin-bottom: 82px;
				height: 54px;
			}
			input {
				box-sizing: border-box;
				text-align: left;
				background-color: rgba(22, 22, 22, 1);
				border: 1px solid rgba(41, 41, 41, 1);
				width: 100%;
				font-size: 14px;
				font-weight: 400;
				color: #ffffff;
				white-space: wrap; //溢出不换行
				padding: 0 20px;
				&:placeholder {
					color: #505050;
					font-size: 14px;
					font-weight: 400;
				}
			}
			textarea {
				margin-bottom: 30px;
				outline: none;
				background-color: rgba(22, 22, 22, 1);
				font-size: 14px;
				font-weight: 400;
				color: #ffffff;
				box-sizing: border-box;
				border: none;
				resize: none;
				display: block;
				width: 100%;
				height: 237px;
				padding: 20px;
			}
			.button {
				display: flex;
				justify-content: center;
				align-items: center;
				color: #000;
				font-size: 20px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				width: 100%;
				height: 60px;
				border-radius: 5px;
				background: linear-gradient(90deg, #fdaa33 0%, #1f78d3 100%), #f37021;
			}
		}
	}
	.flagBox {
		position: absolute;
		z-index: 99;
		top: 50px;
		width: 100vw;
		height: calc(100vh - 50px);
		background: rgba(0, 0, 0, 0.8);
		.subBox {
			width: 308px;
			height: 205px;
			display: inline-flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background: #343434;
			margin: 200px 33px 0;
			border-radius: 30px;
			.ok {
				width: 55px;
			}
			.font {
				margin: 19px 0;
				color: #fff;
				font-family: Lexend;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}
			.button {
				width: 170px;
				height: 30px;
				display: flex;
				// padding: 10px 60px;
				justify-content: center;
				align-items: center;
				border-radius: 4px;
				background: linear-gradient(90deg, #fdaa33 0%, #1f78d3 100%);
				color: #000;
				font-size: 16px;
				font-weight: 400;
			}
		}
	}
}
@media screen and (min-width: 768px) {
	.support {
		width: 100%;
		background: rgba(0, 0, 0, 1);
		color: #ffffff;
		.banner {
			width: 100%;
			height: 400px;
			background: url('../assets/support/banner.png');
			background-size: 1920px 400px;
			display: flex;
			flex-direction: column;
			align-items: center;
			.title {
				margin: 130px auto 13px;
				font-weight: 500;
				font-size: 64px;
				line-height: 80px;
			}
			.font {
				font-weight: 700;
				font-size: 16px;
				line-height: 52px;
			}
		}
		.body {
			width: 900px;
			height: 680px;
			margin: -64px 510px 0;
			padding: 64px 35px 73px;
			background: rgba(30, 30, 30, 1);
			border-radius: 19px;
			input:nth-child(1) {
				margin-bottom: 28px;
				height: 54px;
			}
			input:nth-child(2) {
				margin-bottom: 30px;
				height: 237px;
			}
			input:nth-child(3) {
				margin-bottom: 82px;
				height: 54px;
			}
			input {
				text-align: center;
				background-color: rgba(22, 22, 22, 1);
				border: 1px solid rgba(41, 41, 41, 1);
				width: 100%;
				font-weight: 400;
				font-size: 20px;
				color: #ffffff;
				&:placeholder {
					color: #505050;
					font-size: 16px;
				}
			}
			textarea {
				text-align: center;
				margin-bottom: 30px;
				outline: none;
				background-color: rgba(22, 22, 22, 1);
				font-size: 20px;
				font-weight: 400;
				color: #ffffff;
				box-sizing: border-box;
				border: none;
				resize: none;
				display: block;
				width: 100%;
				height: 237px;
				padding: 20px;
			}
			.button {
				display: flex;
				justify-content: center;
				align-items: center;
				color: #000;
				font-size: 20px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				width: 100%;
				height: 60px;
				border-radius: 5px;
				background: linear-gradient(90deg, #fdaa33 0%, #1f78d3 100%), #f37021;
			}
		}
	}
	.flagBox {
		position: absolute;
		z-index: 99;
		top: 80px;
		width: 100vw;
		height: calc(100vh - 80px);
		background: rgba(0, 0, 0, 0.8);
		.subBox {
			width: 600px;
			height: 400px;
			display: inline-flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background: #343434;
			margin: 432px 0 0 662px;
			border-radius: 30px;
			.ok {
				width: 108px;
			}
			.font {
				margin: 37px 0;
				color: #fff;
				font-family: Lexend;
				font-size: 30px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}
			.button {
				width: 340px;
				height: 58px;
				display: flex;
				// padding: 10px 60px;
				justify-content: center;
				align-items: center;
				border-radius: 6px;
				background: linear-gradient(90deg, #fdaa33 0%, #1f78d3 100%);
				color: #000;
				font-size: 30px;
				font-weight: 400;
			}
		}
	}
}
</style>
